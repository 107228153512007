import { Card, CardContent, Typography } from '@mui/material';
import { Post } from './util/post';

function PostCard(post: Post) {
  return (
    <div className="post">
      <Card className="card" sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
        <CardContent>
          <Typography variant="h5" component="div">
            {post.title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {post.content}
          </Typography>
        </CardContent>
      </Card>

      <span style={{ display: 'block', height: '1rem' }} />
    </div>
  );
}

export default PostCard;