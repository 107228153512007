import post from '../../components/util/post'
import PostCard from '../../components/Post'
import Layout from '../../components/Layout/Layout'
import { Button, Container } from '@mui/material'

function ViewReports() {
  return (
    <Layout>
      <Container>
        <h1>View Reports</h1>
        {post.getAllPosts().map((post, _) => {
          return <PostCard title={post.title} content={post.content} />
        })}

        <Button variant="contained" href="/reports/create">Create Post</Button>
      </Container>
    </Layout>
  )
}

export default ViewReports
