export interface Post {
  title: string
  content: string
}

const Posts: Post[] = [
  {
    title: 'Hello World',
    content: 'This is the first post',
  },
  {
    title: 'Hello World 2',
    content: 'This is the second post',
  }
]

function createPost(post: Post) {
  Posts.push(post)
  console.log(Posts)
}

function getAllPosts() {
  return Posts
}

function deletePost(post: Post) {
  const index = Posts.indexOf(post)
  Posts.splice(index, 1)
}

export default { createPost, getAllPosts, deletePost }
