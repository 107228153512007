import { Slide, useScrollTrigger } from "@mui/material";

function HideOnScroll(props: any) {
  const trigger = useScrollTrigger();
  return (
    <Slide in={!trigger}>
      <div>{props.children}</div>
    </Slide>
  );
}

export default HideOnScroll;
