import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Switch,
} from "@mui/material";
import Layout from "../components/Layout/Layout";
import { login } from "../components/util/user";

function onSubmit(event: React.FormEvent<HTMLFormElement>) {
  event.preventDefault();
  const email = (document.getElementById("email") as HTMLInputElement).value;
  const password = (document.getElementById("password") as HTMLInputElement).value;

  if (login(email)) {
    window.location.href = "/dashboard";
  } else {
    alert("Invalid email or password");
  }
}

function LoginForm() {
  return (
    <Box
      className="login-form"
      component="form"
      autoComplete="off"
      onSubmit={onSubmit}
    >
      <h1>Login</h1>

      <FormControl>
        <div>
          <InputLabel htmlFor="email">Email</InputLabel>
          <OutlinedInput id="email" label="Email" required/>
        </div>
      </FormControl>

      <span style={{display: "block", height: "1rem"}}/>

      <FormControl>
        <div>
          <InputLabel htmlFor="password">Password</InputLabel>
          <OutlinedInput id="password" type="password" label="Password" required/>
        </div>
      </FormControl>

      <FormHelperText>Forgot Password?</FormHelperText>
      <FormControlLabel control={<Switch/>} label="Remember me"/>
      <br/>
      <Button variant="contained" type="submit">Login</Button>
    </Box>
  );
}

function Login() {
  return (
    <Layout>
      <LoginForm/>
    </Layout>
  );
}

export default Login;
