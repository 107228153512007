import { redirect } from 'react-router-dom'
import Layout from '../components/Layout/Layout'
import { Button, Container } from '@mui/material'

function Dashboard() {
  return (
    <Layout>
      <Container maxWidth="md">
        <h1>Welcome to your dashboard</h1>

        <Button
          className="myButton"
          variant="contained"
          color="primary"
          onClick={() => window.location.href = '/reports'}
          sx={{m: 2, p: 2}}
        >
          Reports
        </Button>

        <Button
          className="myButton"
          variant="contained"
          color="primary"
          onClick={() => window.location.href = '/meetings'}
          sx={{m: 2, p: 2}}
        >
          Meetings
        </Button>

      </Container>
    </Layout>
  )
}

export default Dashboard
