export interface User {
  email: string
  password: string

}

const users: User[] = [
  {
    email: 'admin@example.com',
    password: 'password123',
  },
]

export const login = (email: string) => {
  const user = users.find((user) => user.email === email)
  if (user) {
    localStorage.setItem('supervisor-system-user', JSON.stringify(user))
    return true
  }
  return false
}

export const logout = () => {
  localStorage.removeItem('supervisor-system-user')
}
