import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {RouterProvider, createBrowserRouter} from "react-router-dom";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import CreateReport from "./pages/posts/create";
import ViewReports from "./pages/posts/view";
import BookMeeting from "./pages/meetings/book";
import ViewMeetings from "./pages/meetings/view";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
  },
  {
    path: "/login",
    element: <Login/>,
  },
  {
    path: "/dashboard",
    element: <Dashboard/>,
  },
  {
    path: "/reports/create",
    element: <CreateReport/>,
  },
  {
    path: "/reports",
    element: <ViewReports/>,
  },
  {
    path: "/meetings/book",
    element: <BookMeeting/>,
  },
  {
    path: "/meetings",
    element: <ViewMeetings/>,
  }
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
