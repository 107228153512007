import {
  AppBar,
  Box,
  IconButton,
  MenuItem,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { Link, redirect } from 'react-router-dom'
import React from 'react'
import { logout } from './util/user'

interface AppBarProps {
  isLoggedIn?: boolean
}

export const NavBar: React.FC<AppBarProps & { isLoggedIn?: boolean }> = ({ isLoggedIn }) => {
  const isMobile = useMediaQuery('(max-width: 600px)')

  const handleClick = (path: string) => () => {
    redirect(path)
  }

  return (
    <AppBar position="static">
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between'}}>
        {isMobile && (
          <IconButton edge="start" color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton>
        )}
        <Typography variant="h6" noWrap component="div">
          Supervisor System
        </Typography>
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          <MenuItem component={Link} to="/">
            <Typography textAlign="center">Home</Typography>
          </MenuItem>
          <MenuItem component={Link} to="/reports">
            <Typography textAlign="center">Reports</Typography>
          </MenuItem>
          <MenuItem component={Link} to="/meetings">
            <Typography textAlign="center">Meetings</Typography>
          </MenuItem>
          {isLoggedIn ? (
              <MenuItem component={Link} to="/" onClick={logout}>
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
            ) : (
              <MenuItem component={Link} to="/login">
                <Typography textAlign="center">Login</Typography>
              </MenuItem>
            )}
        </Box>
      </Toolbar>
    </AppBar>
  )
}
