export interface Meeting {
  title: string
  date: Date
}

export const meetings: Meeting[] = [
  {
    title: 'Meeting 1',
    date: new Date(),
  },
]
