import { NavBar } from '../Navbar'
import HideOnScroll from '../HideOnScroll'
import React from 'react';
import { User } from '../util/user';

function Layout(props: any) {
  const [ loggedInUser, setLoggedInUser ] = React.useState<User | null>(null);

  React.useEffect(() => {
    const user = localStorage.getItem('supervisor-system-user');
    if (user) {
      setLoggedInUser(JSON.parse(user));
    }
  }, []);

  const isLoggedIn = !!loggedInUser;

  return (
    <HideOnScroll props>
      <NavBar isLoggedIn={isLoggedIn} />
      <main>{props.children}</main>
    </HideOnScroll>
  )
}

export default Layout
