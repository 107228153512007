import PostCard from '../../components/Post'
import Layout from '../../components/Layout/Layout'
import { Button, Container } from '@mui/material'
import { meetings } from '../../components/util/meeting'

function ViewMeetings() {
  return (
    <Layout>
      <Container>
        <h1>View Meetings</h1>
        {meetings.map((meeting, _) => {
          return <PostCard title={meeting.title} content={meeting.date.toLocaleString()} />
        })}

        <Button variant="contained" href="/meetings/book">
          Book Meeting
        </Button>
      </Container>
    </Layout>
  )
}

export default ViewMeetings
