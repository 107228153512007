import { Alert, Box, Button, FormControl, TextField } from '@mui/material'
import Layout from '../../components/Layout/Layout'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import CheckIcon from '@mui/icons-material/Check'
import { useState } from 'react'

interface AlertProps {
  success: boolean;
  message: string;
}

function BookMeeting() {
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState('');

  const handleSubmit = (event: React.FormEvent<HTMLInputElement>) => {
    event.preventDefault();
    setSuccess(true);
    setMessage('Meeting booked successfully');
  }

  const AlertMessage = ({ success, message }: AlertProps) => {
    return (
      <Alert severity={success ? 'success' : 'error'} sx={{ display: success ? 'flex' : 'none' }}>
        {success && <CheckIcon sx={{ marginRight: 1 }} />}
        {message}
      </Alert>
    )
  }

  return (
    <Layout>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box
          component="form"
          sx={{ display: 'flex', flexDirection: 'column', gap: 2, padding: 10 }}
          onSubmit={handleSubmit}
        >
          <FormControl required>
            <DatePicker label="Select Date" />
          </FormControl>

          <FormControl>
            <TextField label="Meeting title" variant="outlined" required />
          </FormControl>
          <FormControl>
            <TextField label="Meeting description" variant="outlined" required />
          </FormControl>
          <Button variant="contained" type="submit">
            Book Meeting
          </Button>

          <FormControl>
            <AlertMessage success={success} message={message} />
          </FormControl>
        </Box>
      </LocalizationProvider>
    </Layout>
  )
}

export default BookMeeting
